import React from "react";
import SvgIcon from "../SvgIcon";

const Hamburger = () => {
  return (
    <SvgIcon width="50" height="50" viewBox="0 0 50 50" fill="#FFFFFF">
      <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z" />
    </SvgIcon>
  );
};

export default Hamburger;
